import React from 'react'
import Layout from '../components/indexLayout'

import logoROHSGif from '../images/firstbond/Logo_ROHS.gif'
import blancGif from '../images/firstbond/blanc.gif'
import rougeGif from '../images/firstbond/rouge-1.gif'
import bleuGif from '../images/firstbond/bleu.gif'
import anthraciteGif from '../images/firstbond/anthracite.gif'
import jauneGif from '../images/firstbond/jaune.gif'
import noirGif from '../images/firstbond/noir.gif'
import argentGif from '../images/firstbond/argent.gif'
import aluminiumGif from '../images/firstbond/alu.gif'
import chocolatGif from '../images/firstbond/chocolat-ral-8011.gif'
import ivoireGif from '../images/firstbond/panneau-dibond-ivoire-ral-1015.gif'
import bordeauxGif from '../images/firstbond/panneau-dibond-bordeaux-ral-3004.gif'
import fuschiaPng from '../images/firstbond/panneau-dibond-fuschia-4010.png'
import vertTraficPng from '../images/firstbond/panneau-dibond-vert-trafic-6024.png'
import vertAnglaisPng from '../images/firstbond/panneau-dibond-vert-anglais-ral-6005.png'
import orangePng from '../images/firstbond/panneau-dibond-orange-ral-2011.png'
import miroirArgentPng from '../images/firstbond/panneau-dibond-miroir-argent.png'
import miroirOrPng from '../images/firstbond/panneau-dibond-miroir-or.png'
import goldBrossePng from '../images/firstbond/panneau-dibond-gold-brosse.png'
import cuivreBrossePng from '../images/firstbond/panneau-dibond-cuivre-brosse.png'

const TarifsPage = () => (
  <Layout>
    <div className="page white-bg">
      <div className="container">
        
        <section className="hero-section">
        
          <div id="card">

          <table width="100%" border="0" cellSpacing="0" cellPadding="2">
            <thead>
              <tr>
                <th width="5%">&nbsp;</th>
                <th width="10%">Couleurs</th>
                <th width="10%">Epaisseur (mm)</th>
                <th width="10%">Aluminium (mm)</th>
                <th width="10%">Prix/m² (informatif)</th>
                <th width="55%" colSpan="5">Prix panneaux (HT)</th>
                {/*<th width="55%" colSpan="3">Prix panneaux (HT)</th>*/}
              </tr>
              <tr>
                <td width="5%">&nbsp;</td>
                <td width="10%">&nbsp;</td>
                <td width="10%">&nbsp;</td>
                <td width="10%">&nbsp;</td>
                <td width="10%">&nbsp;</td>
                
                <td width="11%" className="header-highlight">1000x2025</td>
                <td width="11%" className="header-highlight">1220x2440</td>
                <td width="11%" className="header-highlight">1250x2550 </td>
                <td width="11%" className="header-highlight">1220x3050</td>
                <td width="11%" className="header-highlight">1500x3050</td>
                
                {/*
                <td width="18%" className="header-highlight">1000x2050</td>
                <td width="18%" className="header-highlight">1220x2440</td>
                <td width="19%" className="header-highlight">1250x2550 </td>
                */}
              </tr>              
            </thead>
            <tbody>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={blancGif} width="22" height="10"/></td>
                <td>blanc ral 9016 </td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >2</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >24,60 €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">112,66 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={blancGif} width="22" height="10"/></td>
                <td>blanc ral 9016 </td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,21</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >25,80 €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">52,25 €</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">82,30 €</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">118,16 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={blancGif} width="22" height="10"/></td>
                <td>blanc ral 9016 </td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >4</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >31,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">145,64 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={rougeGif} width="22" height="10"/></td>
                <td>rouge ral 3020</td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >30,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">141,06 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={bleuGif} width="22" height="10"/></td>
                <td>bleu ral 5002 </td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >30,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">141,06 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={anthraciteGif} width="22" height="10"/></td>
                <td>gris anthracite&nbsp;7016</td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >30,80 €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">&nbsp;</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">141,06 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={jauneGif} width="22" height="10"/></td>
                <td>jaune ral 1023</td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >30,80 €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">&nbsp;</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">141,06 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={noirGif} width="22" height="10"/></td>
                <td>noir ral 9005 </td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >30,80 €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">&nbsp;</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">141,06 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={argentGif} width="22" height="10"/></td>
                <td>gris métallisé ral 9006 </td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >30,80 €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">&nbsp;</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">141,06 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={chocolatGif} width="22" height="10"/></td>
                <td>chocolat ral 8011 </td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >30,80 €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">&nbsp;</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">141,06 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={ivoireGif} width="22" height="10"/></td>
                <td>ivoire ral 1015 </td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >30,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">&nbsp;</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">141,06 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={bordeauxGif} width="22" height="10"/></td>
                <td>bordeaux ral 3004 </td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >30,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">&nbsp;</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">141,06 €</td>
              </tr>
                    
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={fuschiaPng} width="22" height="10"/></td>
                <td>fuschia 4010</td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >30,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">&nbsp;</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">141,06 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={vertTraficPng} width="22" height="10"/></td>
                <td>vert trafic 6024</td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >30,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">&nbsp;</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">141,06 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={vertAnglaisPng} width="22" height="10"/></td>
                <td>vert anglais ral 6005</td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >30,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">&nbsp;</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">141,06 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={orangePng} width="22" height="10"/></td>
                <td>orange ral 2011</td>
                <td data-label="Epaisseur (mm)"              >3</td>
                <td data-label="Aluminium (mm)"              >0,30</td>
                <td data-label="Prix/m² (informatif)"        >30,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)">&nbsp;</td>
                <td data-label="Prix panneaux 1250x2550	(HT)">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)">141,06 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={miroirArgentPng} width="22" height="10"/></td>
                <td>miroir argent</td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >75,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">281,98 €</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={miroirOrPng} width="22" height="10"/></td>
                <td>miroir or</td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >75,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">225,88 €</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={goldBrossePng} width="22" height="10"/></td>
                <td>gold brossé </td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >40,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">151,78 €</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={cuivreBrossePng} width="22" height="10"/></td>
                <td>cuivre brossé </td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >40,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">151,78 €</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={aluminiumGif} width="22" height="10"/></td>
                <td>aluminium brossé </td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >38,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">&nbsp;</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">177,70 €</td>
              </tr>
            </tbody>
          </table>

          </div>

        </section>

        <section className="hero-section">
        <div className="info-area">
          <b>
            Port de livraison: 66,36 €<br/><br/>
            Le tarif de livraison est forfaitaire et illimité en poids.
            <br/>
            La livraison a lieu au pied du fourgon.<br/>
            Le client doit aider notre chauffeur-livreur à décharger les panneaux<br/>
            à l'emplacement que vous avez réservé.<br/>
            <br/>
          </b>
        </div>
        </section>


        <section className="hero-section">
          <div className="info-area">
            <div className="logo-rohs"><img src={logoROHSGif} alt="ROHS" width="121" height="65"/></div>
            <div className="mention-rohs">
              Tous nos produits sont certifiés CE et à la norme RoHS.
            </div>
          </div>
        </section>

      </div>

    </div>

  </Layout>
)

export default TarifsPage
